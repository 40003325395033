import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

import RssFeedTwoToneIcon from '@material-ui/icons/RssFeedTwoTone'
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone'
import ForumTwoToneIcon from '@material-ui/icons/ForumTwoTone'
import PhotoCameraTwoToneIcon from '@material-ui/icons/PhotoCameraTwoTone'

const StyledBottomNavigation = styled(BottomNavigation)`
  ${({theme}) => `
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
  `}
`

const Navigations = (props) => {
  const {history, location} = props

  const [nav, setNav] = useState(0)

  useEffect(() => {

    const {pathname} = location
    if (pathname === '/') {
      setNav(0)
    } else if (pathname.match(/^\/accounts/) || pathname.match(/^\/account/)) {
      setNav(1)
    } else if (pathname.match(/^\/pages/)) {
      setNav(2)
    } else if (pathname.match(/^\/instagram/)) {
      setNav(3)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function goTo (to) {
    history.push(to)
  }

  return (
    <StyledBottomNavigation value={nav} onChange={(e, v) => setNav(v)}>
      <BottomNavigationAction label="Homepage" icon={<RssFeedTwoToneIcon />} onClick={() => goTo('/')} />
      <BottomNavigationAction label="Account" icon={<PeopleAltTwoToneIcon />} onClick={() => goTo('/accounts')} />
      <BottomNavigationAction label="Facebook" icon={<ForumTwoToneIcon />} onClick={() => goTo('/pages')} />
      <BottomNavigationAction label="Instagram" icon={<PhotoCameraTwoToneIcon />} onClick={() => goTo('/instagram')} />
    </StyledBottomNavigation>
  )
}

export default withRouter(Navigations)