import React from 'react'
import {Provider} from 'react-redux'
import {ThemeProvider} from 'styled-components'
import {BrowserRouter as Router} from 'react-router-dom'

import {createMuiTheme, ThemeProvider as MuiThemeProvider, StylesProvider} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import NoSsr from '@material-ui/core/NoSsr'

import store from './reducer'
import Content from './content'

const customTheme = createMuiTheme()

export default function App(props) {
  return (
    <Provider store={store}>
      <Router>
        <NoSsr>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={customTheme}>
              <ThemeProvider theme={customTheme}>
                <Content />
              </ThemeProvider>
            </MuiThemeProvider>
          </StylesProvider>
          <CssBaseline/>
        </NoSsr>
      </Router>
    </Provider>
  )
}
