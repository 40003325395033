import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import _ from 'lodash'

import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import datepickerStyle from './datepicker-style'
import Avatar from '@material-ui/core/Avatar'
import {deleteAccount} from './actions'

const StyledDiv = styled.div`
  ${({theme}) => `
    .form-container {
      padding-right: ${theme.spacing(2)}px;
      
      @media (max-width: ${theme.breakpoints.values.md}px) {
        padding-right: 0px;
      }
      
      .avatar {
        height: 60px;
        width: 60px;
        margin-right: ${theme.spacing(2)}px;
      }
      
      .expired {
        margin-top: ${theme.spacing(2)}px;
      }
    
      .divider {
        margin: ${theme.spacing(2)}px 0px;
      }
    }
    
    .btn-container {
      padding-left: ${theme.spacing(1)}px;
      border-left: dotted 1px ${theme.palette.divider};
      min-height: calc(100vh - 48px - 16px - 56px);
      
      @media (max-width: ${theme.breakpoints.values.md}px) {
        padding: ${theme.spacing(2)}px 0px;
        border-left: 0px;
        min-height: unset;
        padding-bottom: 54px;
      }
      
      button {
        margin-bottom: ${theme.spacing(1)}px;
      }
    }
  
    ${datepickerStyle}
    
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .datepicker-container {
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  `}
`

export default function Accountaccount(props) {

  const {account, setLoading, setAccount, setErrMsg, updateAccount, impersonate, deleteAccount} = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [isDisabled, setIsDisabled] = useState(false)
  const [isTrial, setIsTrial] = useState(true)
  const [expiredAt, setExpiredAt] = useState(dayjs().toDate())
  const [shortcut, setShorcut] = useState(0)

  useEffect(() => {
    if (!_.isEmpty(account)) {
      setExpiredAt(dayjs({...account}.expiredAt).toDate())
      setIsTrial(account.isTrial)
      setIsDisabled(dayjs({...account}.expiredAt).startOf('date') < dayjs().startOf('date'))
    }
  }, [account])

  function disableAccount(v) {
    setIsDisabled(v)

    if (v) {
      setExpiredAt(dayjs().subtract(1, 'd').toDate())
    } else {
      setExpiredAt(dayjs({...account}.expiredAt).toDate())
    }
  }

  function setShortcut(e) {
    const totalMonth = e.target.value
    setShorcut(totalMonth)
    if (totalMonth === 0) {
      setExpiredAt(dayjs({...account}.expiredAt).toDate())
    } else {
      const diff = dayjs({...account}.expiredAt).diff(dayjs().startOf('day'), 'd')
      const from = diff < 0 ? dayjs() : dayjs({...account}.expiredAt)

      let newExpiredDate = from.add(totalMonth, 'month').add(1, 'day')

      if (totalMonth === 12) {
        newExpiredDate = newExpiredDate.add(14, 'day').toDate()
      } else if (totalMonth === 16) {
        // newExpiredDate = newExpiredDate.add(4, 'month').toDate()
      } else {
        newExpiredDate = newExpiredDate.toDate()
      }

      setExpiredAt(newExpiredDate)
    }
  }

  function saveAccount() {
    setLoading(true)
    updateAccount({id: account.id, isTrial, expiredAt})
      .then(response => setAccount(response.data))
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            setErrMsg(err.response.data.msg)
          }
        } else {
          console.log(err)
        }
      })
      .then(() => setLoading(false))
  }

  function deleteAccountO () {
    setLoading(true)
    deleteAccount({id: account.id})
      .then(() => window.location.reload())
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            setErrMsg(err.response.data.msg)
          }
        } else {
          console.log(err)
        }
      })
      .then(() => setLoading(false))
  }

  function beginImpersonate() {
    impersonate(account)
      .then(response => {
        window.open(`https://app.bizsaya.com?token=${response.data.token}`, '_blank')
      })
      .catch(err => {
        alert(err.toString())
      })
  }

  return (
    <StyledDiv>
      <Grid container>
        <Grid className={'form-container'} item xs={12} md={8}>
          <Grid container>
            <Grid item>
              <Avatar
                className={'avatar'}
                src={`https://graph.facebook.com/v2.10/${account.fbId}/picture`}
                alt='Gambar facebook'
              />
            </Grid>
            <Grid item xs>
              <Typography variant={isMobile ? 'h6' : 'h4'}>{account.name}</Typography>
              <Typography component={'div'}>{account.fbId}</Typography>
              <Typography variant={'caption'}
                          component={'div'}>Register : {dayjs(account.createdAt).format('DD MMM YYYY hh:mm:ss A')}</Typography>
              <Typography variant={'caption'}
                          component={'div'}>Expired : {dayjs(account.expiredAt).add(23, 'hour').add(59, 'minute').add(59, 'second').format('DD MMM YYYY hh:mm:ss A')}</Typography>
            </Grid>
          </Grid>
          <Divider className={'divider'}/>
          <FormGroup>
            <FormControlLabel
              control={(
                <Switch
                  color={'primary'}
                  checked={isDisabled}
                  onChange={e => disableAccount(e.target.checked)} />
              )}
              label="Disabled account"
            />
            <FormControlLabel
              control={(
                <Switch
                  color={'primary'}
                  checked={!isTrial}
                  onChange={e => setIsTrial(!e.target.checked)} />
              )}
              label="Paid account"
            />
          </FormGroup>
          <>
            <Typography className='expired'>Tarikh tamat</Typography>
            <Box className='datepicker-container'>
              <DatePicker
                readOnly={isDisabled}
                minDate={dayjs().toDate()}
                highlightDates={dayjs({...account}.expiredAt).toDate()}
                selected={expiredAt}
                onChange={date => setExpiredAt(date)}
                inline
                disabledKeyboardNavigation
              />
            </Box>

            <Typography className='expired'>Bayaran untuk</Typography>
            <Select fullWidth value={shortcut} onChange={setShortcut}>
              <MenuItem value={0}>Sila pilih</MenuItem>
              <MenuItem value={1}>Sebulan</MenuItem>
              <MenuItem value={2}>2 Bulan</MenuItem>
              <MenuItem value={3}>3 Bulan</MenuItem>
              <MenuItem value={4}>4 Bulan</MenuItem>
              <MenuItem value={5}>5 Bulan</MenuItem>
              <MenuItem value={6}>6 Bulan</MenuItem>
              <MenuItem value={7}>7 Bulan</MenuItem>
              <MenuItem value={8}>8 Bulan</MenuItem>
              <MenuItem value={9}>9 Bulan</MenuItem>
              <MenuItem value={10}>10 Bulan</MenuItem>
              <MenuItem value={11}>11 Bulan</MenuItem>
              <MenuItem value={12}>Setahun</MenuItem>
              <MenuItem value={16}>Setahun (promo 4 bulan)</MenuItem>
            </Select>
          </>
        </Grid>
        <Grid className={'btn-container'} item xs={12} md={4}>
          <Button
            fullWidth
            color={'primary'}
            variant={'outlined'}
            onClick={saveAccount}
          >
            Simpan dan kemaskini
          </Button>
          <Button
            fullWidth
            variant={'outlined'}
            onClick={beginImpersonate}
          >
            Impersonate
          </Button>
          <Button
            fullWidth
            color={'secondary'}
            variant={'outlined'}
            onClick={deleteAccountO}
          >
            Padam
          </Button>
        </Grid>
      </Grid>
    </StyledDiv>
  )
}
