import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import Pusher from 'pusher-js'
import Cookies from 'js-cookie'

import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'

import Login from './login'
import Dashboard from './dashboard'
import Accounts from './accounts'
import Account from './account'
import Pages from './pages'
import Instagram from './instagram'
import Navigations from './navigation'
import {setToken as setAxiosToken} from './actions'


const StyledDiv = styled.div`
  ${({theme}) => `
    .loading {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      cursor: progress;
    }
    .content {
      min-height: 100vh;
      width: 100vw;
      padding-bottom: 58px;
      display: flex;
      flex-direction: column;
    }
  `}
`

const Content = (props) => {
  const { loading, addLogs, setAxiosToken } = props

  const [token, setToken] = useState('')

  useEffect(() => {
    const sessionCookie = Cookies.get('ssid')

    if (!_.isEmpty(_.trim(sessionCookie))) {
      setToken(sessionCookie)
      setAxiosToken(sessionCookie)
      initializePusher()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function setTheCookie(cookie) {
    if (!_.isEmpty(_.trim(cookie))) {
      Cookies.set('ssid', cookie)
      setToken(cookie)
      setAxiosToken(cookie)
      initializePusher()
    }
  }

  function initializePusher() {
    const pusher = new Pusher('5d02e863867c2b29ebcf', {
      cluster: 'ap1',
      encrypted: true
    })

    const channel = pusher.subscribe('cnc_logs')

    channel.bind('log', function (data) {
      addLogs(data)
    })
  }

  return (
    <StyledDiv>
      {
        loading && (
          <Box className={'loading'}>
            <LinearProgress />
          </Box>
        )
      }

      {
        _.isEmpty(_.trim(token)) && (
          <Box className={'content'}><Login setToken={setTheCookie}/></Box>
        )
      }

      {
        !_.isEmpty(_.trim(token)) && (
          <>
            <Box className={'content'}>
              <Switch>
                <Route path='/' exact component={Dashboard}/>
                <Route path='/accounts' exact component={Accounts} />
                <Route path='/accounts/:id' exact component={Account} />
                <Route path='/pages' exact component={Pages}/>
                <Route path='/instagram' exact component={Instagram}/>
                <Route component={Dashboard}/>
              </Switch>
            </Box>

            <Navigations/>
          </>
        )
      }
    </StyledDiv>
  )
}

export default connect((state) => ({...state.cnc}), (dispatch) => ({
  addLogs: log => {
    dispatch({type: 'ADD_LOGS', data: log})
  },
  setAxiosToken
}))(Content)