import React, {useState} from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'

import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Snackbar from '@material-ui/core/Snackbar'


import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone'
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone'
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone'
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone'
import SyncDisabledTwoToneIcon from '@material-ui/icons/SyncDisabledTwoTone'
import SyncTwoToneIcon from '@material-ui/icons/SyncTwoTone'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone'

import datepickerStyle from './datepicker-style'

const StyledDiv = styled.div`
  ${({theme}) => `
    > button {
      margin: ${theme.spacing(0)}px 0px ${theme.spacing(2)}px 0px;
    }
  
    .items {
      > div {
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: ${theme.spacing(1)}px;
        
        a {
          text-decoration: none;
        }
        
        &.diconnected {
          background-color: rgba(255, 0, 0, 0.3);
        }
        
        &.unconfirm {
          background-color: rgba(253, 253, 87, 0.4)
        }
        
        &.expired {
          background-color: rgba(253, 253, 87, 0.4)
        }
        
        > div {
          width: 100%;
          
          &:first-child {
            flex-grow: 1;     
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            .selector {
              width: 192px;
            }
            
            .page-btn-container {
              margin: ${theme.spacing(2)}px 0px 0px 0px;
            }
          }
        }                      
      }
    }
      
    ${datepickerStyle}
  `}
`

export default function Accountinstagram(props) {

  const {
    originalInstagramInsta,
    instagramsInsta,
    setLoading,
    setErrMsg,
    setInstagramInsta,
    getAccountManually,
    updateInstagram,
    deleteInstagram: deleteAInstagram
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [shortcut, setShortcut] = useState(0)
  const [deleteInstagramInsta, setDeleteInstagramInsta] = useState({})
  const [snackbarMsg, setSnackbarMsg] = useState('')

  function reset () {
    getAccountManually()
  }

  function saveAllInstagram () {

  }

  function setInstagramExpiredDate(instagram, newDate, viaShortcut = false) {
    const index = _.findIndex(instagramsInsta, {id: instagram.id})
    const instagramsInstaClone = [...instagramsInsta]

    if (index !== -1) {
      instagramsInstaClone[index].expiredAt = newDate
      setInstagramInsta(instagramsInstaClone)
    }
  }

  function shortcutDate(e, page) {
    const totalMonth = e.target.value
    setShortcut(e.target.value)
    if (totalMonth === 0) {
      const date = dayjs({...page}.expiredAt).toDate()
      setInstagramExpiredDate(page, date, true)
    } else {
      const diff = dayjs({...page}.expiredAt).diff(dayjs().startOf('day'), 'd')
      const from = diff < 0 ? dayjs() : dayjs({...page}.expiredAt)

      let newExpiredDate = from.add(totalMonth, 'month').add(1, 'day')

      if (totalMonth === 12) {
        newExpiredDate = newExpiredDate.add(14, 'day').toDate()
      } else {
        newExpiredDate = newExpiredDate.toDate()
      }

      setInstagramExpiredDate(page, newExpiredDate, true)
    }
  }

  function btnSave(igId, key, value) {
    const index = _.findIndex(instagramsInsta, {id: igId})
    const instagramsInstaClone = [...instagramsInsta]
    if (index !== -1) {
      instagramsInstaClone[index][key] = value

      if (key === 'isEnable' && value === false) {
        instagramsInstaClone[index].isActive = false
        instagramsInstaClone[index].isConnected = false
      } else if (key === 'isActive') {
        if (value === false) {
          instagramsInstaClone[index].isConnected = false
        } else {
          instagramsInstaClone[index].isEnable = true
        }
      } else if (key === 'isConnected' && value === true) {
        instagramsInstaClone[index].isEnable = true
        instagramsInstaClone[index].isActive = true
      }

      setInstagramInsta(instagramsInstaClone)
    }
  }

  function saveInstagram(igId) {
    setLoading(true)
    const instagramsInstaClone = [...instagramsInsta]
    const instagram = _.find(instagramsInsta, {id: igId})
    const index = _.findIndex(instagramsInsta, {id: igId})

    if (index === -1) {
      return null
    }

    updateInstagram(instagram)
      .then((response) => {
        if (response.data.updateButNotConnected) {
          setSnackbarMsg(`Instagram fail to be connected due to access token issue. Please ask user to recheck access token`)
        }

        if (index !== -1) {
          instagramsInstaClone[index] = response.data.instagram
          setInstagramInsta(instagramsInstaClone)
        }
      })
      .then(() => setSnackbarMsg('Instagram updated'))
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            return setErrMsg(err.response.data.msg)
          }
        }

        alert(err.toString())
      })
      .then(() => {
        setLoading(false)
        setShortcut(0)
      })
  }

  function confirmDeleteInstagramInsta () {
    setLoading(true)
    deleteAInstagram(deleteInstagramInsta)
      .then(() => getAccountManually())
      .then(() => setSnackbarMsg('Instagram deleted'))
      .catch(() => {})
      .then(() => {
        setLoading(false)
        setDeleteInstagramInsta({})
      })
  }

  return (
    <StyledDiv>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant={'outlined'}
            onClick={reset}
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={9}>
          <Button
            fullWidth
            color={'primary'}
            variant={'outlined'}
            onClick={saveAllInstagram}
          >
            Save all changes
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {
          instagramsInsta.map(instagram => {
            let classing = ''
            if (instagram.isUnConfirm) {
              classing = 'unconfirm'
            } else if (instagram.isActive && !instagram.isConnected) {
              classing = 'diconnected'
            }

            return (
              <Grid className={'items'} key={instagram.id} item xs={12} md={3}>
                <Paper className={classing}>
                  <Box>
                    <Avatar
                      src={`https://graph.facebook.com/v2.10/${instagram.pageId}/picture`}
                      alt='Icon page'
                    />
                    <Typography>{instagram.igName}</Typography>
                    <a href={`https://www.instagram.com/${instagram.igName}`} target={'_blank'} rel={'noreferrer'}>
                      <Typography variant={'caption'}>{instagram.igId}</Typography>
                    </a>

                    <Box className='datepicker-container pageversion'>
                      <DatePicker
                        dateFormat={'dd MMM yyyy'}
                        minDate={dayjs().subtract(1, 'd').toDate()}
                        highlightDates={dayjs(instagram.expiredAt).toDate()}
                        selected={dayjs(instagram.expiredAt).toDate()}
                        onChange={date => setInstagramExpiredDate(instagram, date)}
                        // customInput={<DatePickerCustomInput />}
                        disabledKeyboardNavigation
                      />
                    </Box>

                    <Select className={'selector'} value={shortcut} onChange={e => shortcutDate(e, instagram)}>
                      <MenuItem value={0}>Sila pilih</MenuItem>
                      <MenuItem value={1}>Sebulan</MenuItem>
                      <MenuItem value={2}>2 Bulan</MenuItem>
                      <MenuItem value={3}>3 Bulan</MenuItem>
                      <MenuItem value={4}>4 Bulan</MenuItem>
                      <MenuItem value={5}>5 Bulan</MenuItem>
                      <MenuItem value={6}>6 Bulan</MenuItem>
                      <MenuItem value={7}>7 Bulan</MenuItem>
                      <MenuItem value={8}>8 Bulan</MenuItem>
                      <MenuItem value={9}>9 Bulan</MenuItem>
                      <MenuItem value={10}>10 Bulan</MenuItem>
                      <MenuItem value={11}>11 Bulan</MenuItem>
                      <MenuItem value={12}>Setahun</MenuItem>
                    </Select>
                  </Box>

                  <Box>
                    <Grid container className={'page-btn-container'}>
                      <Grid item xs>
                        <Tooltip title={instagram.isEnable ? 'Disable instagram' : 'Enable instagram'}>
                          <IconButton
                            color={instagram.isEnable ? 'secondary' : 'default'}
                            onClick={() => btnSave(instagram.id, 'isEnable', !instagram.isEnable)}>
                            {
                              instagram.isEnable ? <CancelTwoToneIcon/> : <CheckCircleTwoToneIcon/>
                            }
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs>
                        <Tooltip title={instagram.isActive ? 'Deactive instagram' : 'Active instagram'}>
                          <IconButton
                            color={instagram.isActive ? 'secondary' : 'default'}
                            onClick={() => btnSave(instagram.id, 'isActive', !instagram.isActive)}>
                            {
                              instagram.isActive ? <ClearTwoToneIcon/> : <DoneTwoToneIcon/>
                            }
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs>
                        <Tooltip title={instagram.isConnected ? 'Disconnect instagram' : 'Reconnect instagram'}>
                          <IconButton
                            color={instagram.isConnected ? 'secondary' : 'default'}
                            onClick={() => btnSave(instagram.id, 'isConnected', !instagram.isConnected)}>
                            {
                              instagram.isConnected ? <SyncDisabledTwoToneIcon/> : <SyncTwoToneIcon/>
                            }
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs>
                        <Tooltip title={'Delete instagram'}>
                          <IconButton color={'secondary'} onClick={() => setDeleteInstagramInsta(instagram)}>
                            <DeleteForeverTwoToneIcon/>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Button
                      fullWidth
                      variant={'outlined'}
                      color={'primary'}
                      onClick={() => saveInstagram(instagram.id)}
                    >
                      Simpan
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            )
          })
        }
      </Grid>

      {
        !_.isEmpty(deleteInstagramInsta) && (
          <Dialog fullScreen={isMobile} open={true} disableBackdropClick>
            <DialogTitle>Confirm to delete instagram</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure to delete this instagram {deleteInstagramInsta.igName}?
                <br/>
                This action cannot be undo
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'outlined'} fullWidth={isMobile} onClick={() => setDeleteInstagramInsta({})}>
                Cancel
              </Button>
              <Button variant={'outlined'} fullWidth={isMobile} color={'secondary'} onClick={confirmDeleteInstagramInsta}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )
      }

      <Snackbar
        open={!_.isEmpty(_.trim(snackbarMsg))}
        autoHideDuration={2000}
        onClose={() => setSnackbarMsg('')}
        message={snackbarMsg}
      />

    </StyledDiv>
  )
}
