import React, {useState} from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'

import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Snackbar from '@material-ui/core/Snackbar'

import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone'
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone'
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone'
import SyncDisabledTwoToneIcon from '@material-ui/icons/SyncDisabledTwoTone'
import SyncTwoToneIcon from '@material-ui/icons/SyncTwoTone'
import SwapCallsTwoToneIcon from '@material-ui/icons/SwapCallsTwoTone'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone'

import datepickerStyle from './datepicker-style'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const StyledDiv = styled.div`
  ${({theme}) => `
    > button {
      margin: ${theme.spacing(0)}px 0px ${theme.spacing(2)}px 0px;
    }
  
    .items {
      > div {
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: ${theme.spacing(1)}px;
        
        a {
          text-decoration: none;
        }
        
        &.diconnected {
          background-color: rgba(255, 0, 0, 0.3);
        }
        
        &.unconfirm {
          background-color: rgba(253, 253, 87, 0.4)
        }
        
        &.expired {
          background-color: rgba(253, 253, 87, 0.4)
        }
        
        > div {
          width: 100%;
          
          &:first-child {
            flex-grow: 1;     
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            .selector {
              width: 192px;
            }
            
            .page-btn-container {
              margin: ${theme.spacing(2)}px 0px 0px 0px;
            }
          }
        }                      
      }
    }
      
    ${datepickerStyle}
  `}
`

export default function Accountpages(props) {

  const {
    originalFbPages,
    fbPages,
    setLoading,
    setErrMsg,
    setFbPages,
    getAccountManually,
    updatePage,
    transferPage,
    deletePage: deleteAPage
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [fbIdTransfer, setFbIdTransfer] = useState('')
  const [pageToTransfer, setPageToTransfer] = useState({})
  const [shortcut, setShortcut] = useState(0)
  const [deletePage, setDeletePage] = useState({})
  const [snackbarMsg, setSnackbarMsg] = useState('')

  function reset() {
    getAccountManually()
  }

  function setPageExpiredDate(page, newDate, viaShortcut = false) {
    console.log(viaShortcut)
    const index = _.findIndex(fbPages, {id: page.id})
    const fbPagesClone = [...fbPages]

    console.log(_.find(originalFbPages, {id: page.id}))


    if (index !== -1) {
      fbPagesClone[index].expiredAt = newDate
      setFbPages(fbPagesClone)
    }
  }

  function btnSave(pageId, key, value) {
    const index = _.findIndex(fbPages, {id: pageId})
    const fbPagesClone = [...fbPages]
    if (index !== -1) {
      fbPagesClone[index][key] = value

      if (key === 'isEnable' && value === false) {
        fbPagesClone[index].isActive = false
        fbPagesClone[index].isConnected = false
      } else if (key === 'isActive') {
        if (value === false) {
          fbPagesClone[index].isConnected = false
        } else {
          fbPagesClone[index].isEnable = true
        }
      } else if (key === 'isConnected' && value === true) {
        fbPagesClone[index].isEnable = true
        fbPagesClone[index].isActive = true
      }

      setFbPages(fbPagesClone)
    }
  }

  function saveAllPage() {
    setLoading(true)
    const promises = []
    fbPages.forEach(page => {
      promises.push(updatePage(page))
    })

    Promise.all(promises)
      .then(response => {
        const pages = []
        response.forEach(r => {
          const {fbPage, updateButNotConnected, updatePageAlso} = r.data
          delete fbPage.Account

          pages.push(fbPage)
          if (updateButNotConnected) {
            setErrMsg(`${fbPage.name} fail to be connected due to access token issue. Please ask user to recheck access token`)
          }

          if (updatePageAlso) {
            getAccountManually()
          }
        })
        setFbPages(pages)
      })
      .then(() => setSnackbarMsg('Pages updated'))
      .catch(err => alert(err.toString()))
      .then(() => {
        setLoading(false)
        setShortcut(0)
      })
  }

  function savePage(pageId) {
    setLoading(true)
    const fbPagesClone = [...fbPages]
    const thePage = _.find(fbPages, {id: pageId})
    const index = _.findIndex(fbPages, {id: pageId})

    if (index === -1) {
      return null
    }

    updatePage(thePage)
      .then((response) => {
        if (response.data.updateButNotConnected) {
          setSnackbarMsg(`Page fail to be connected due to access token issue. Please ask user to recheck access token`)
        }
        if (response.data.updatePageAlso) {
          getAccountManually()
        }

        if (index !== -1) {
          fbPagesClone[index] = response.data.fbPage
          setFbPages(fbPagesClone)
        }
      })
      .then(() => setSnackbarMsg('Page updated'))
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            return setErrMsg(err.response.data.msg)
          }
        }

        alert(err.toString())
      })
      .then(() => {
        setLoading(false)
        setShortcut(0)
      })
  }

  function transferThePage() {
    setLoading(true)
    transferPage(pageToTransfer, fbIdTransfer)
      .then(() => getAccountManually())
      .then(() => setSnackbarMsg('Page transfer'))
      .catch(err => alert(err.toString()))
      .then(() => setLoading(false))
  }

  function shortcutDate(e, page) {
    const totalMonth = e.target.value
    setShortcut(e.target.value)
    if (totalMonth === 0) {
      const date = dayjs({...page}.expiredAt).toDate()
      setPageExpiredDate(page, date, true)
    } else {
      const diff = dayjs({...page}.expiredAt).diff(dayjs().startOf('day'), 'd')
      const from = diff < 0 ? dayjs() : dayjs({...page}.expiredAt)

      let newExpiredDate = from.add(totalMonth, 'month').add(1, 'day')

      if (totalMonth === 12) {
        newExpiredDate = newExpiredDate.add(14, 'day').toDate()
      } else if (totalMonth === 16) {
        // newExpiredDate = newExpiredDate.add(4, 'month').toDate()
      } else {
        newExpiredDate = newExpiredDate.toDate()
      }

      setPageExpiredDate(page, newExpiredDate, true)
    }
  }

  function confirmDeletePage () {
    setLoading(true)
    deleteAPage(deletePage)
      .then(() => getAccountManually())
      .then(() => setSnackbarMsg('Page deleted'))
      .catch(() => {})
      .then(() => {
        setLoading(false)
        setDeletePage({})
      })
  }

  return (
    <StyledDiv>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant={'outlined'}
            onClick={reset}
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={9}>
          <Button
            fullWidth
            color={'primary'}
            variant={'outlined'}
            onClick={saveAllPage}
          >
            Save all changes
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {
          fbPages.map(page => {
            let classing = ''
            if (page.isUnConfirm) {
              classing = 'unconfirm'
            } else if (page.isActive && !page.isConnected) {
              classing = 'diconnected'
            }

            return (
              <Grid className={'items'} key={page.id} item xs={12} md={3}>
                <Paper className={classing}>
                  <Box>
                    <Avatar
                      src={`https://graph.facebook.com/v2.10/${page.fbId}/picture`}
                      alt='Icon page'
                    />
                    <Typography>{page.name}</Typography>
                    <a href={`https://fb.com/${page.fbId}`} target={'_blank'} rel={'noreferrer'}>
                      <Typography variant={'caption'}>{page.fbId}</Typography>
                    </a>

                    <Box className='datepicker-container pageversion'>
                      <DatePicker
                        dateFormat={'dd MMM yyyy'}
                        minDate={dayjs().subtract(1, 'd').toDate()}
                        highlightDates={dayjs(page.expiredAt).toDate()}
                        selected={dayjs(page.expiredAt).toDate()}
                        onChange={date => setPageExpiredDate(page, date)}
                        // customInput={<DatePickerCustomInput />}
                        disabledKeyboardNavigation
                      />
                    </Box>

                    <Select className={'selector'} value={shortcut} onChange={e => shortcutDate(e, page)}>
                      <MenuItem value={0}>Sila pilih</MenuItem>
                      <MenuItem value={1}>Sebulan</MenuItem>
                      <MenuItem value={2}>2 Bulan</MenuItem>
                      <MenuItem value={3}>3 Bulan</MenuItem>
                      <MenuItem value={4}>4 Bulan</MenuItem>
                      <MenuItem value={5}>5 Bulan</MenuItem>
                      <MenuItem value={6}>6 Bulan</MenuItem>
                      <MenuItem value={7}>7 Bulan</MenuItem>
                      <MenuItem value={8}>8 Bulan</MenuItem>
                      <MenuItem value={9}>9 Bulan</MenuItem>
                      <MenuItem value={10}>10 Bulan</MenuItem>
                      <MenuItem value={11}>11 Bulan</MenuItem>
                      <MenuItem value={12}>Setahun</MenuItem>
                      <MenuItem value={16}>Setahun (promo 4 bulan)</MenuItem>
                    </Select>
                  </Box>

                  <Box>
                    <Grid container className={'page-btn-container'}>
                      <Grid item xs>
                        <Tooltip title={page.isEnable ? 'Disable page' : 'Enable page'}>
                          <IconButton color={page.isEnable ? 'secondary' : 'default'}
                                      onClick={() => btnSave(page.id, 'isEnable', !page.isEnable)}>
                            {
                              page.isEnable ? <CancelTwoToneIcon/> : <CheckCircleTwoToneIcon/>
                            }
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs>
                        <Tooltip title={page.isActive ? 'Deactive page' : 'Active page'}>
                          <IconButton color={page.isActive ? 'secondary' : 'default'}
                                      onClick={() => btnSave(page.id, 'isActive', !page.isActive)}>
                            {
                              page.isActive ? <ClearTwoToneIcon/> : <DoneTwoToneIcon/>
                            }
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs>
                        <Tooltip title={page.isConnected ? 'Disconnect page' : 'Reconnect page'}>
                          <IconButton color={page.isConnected ? 'secondary' : 'default'}
                                      onClick={() => btnSave(page.id, 'isConnected', !page.isConnected)}>
                            {
                              page.isConnected ? <SyncDisabledTwoToneIcon/> : <SyncTwoToneIcon/>
                            }
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs>
                        <Tooltip title={'Swap page'}>
                          <IconButton onClick={() => setPageToTransfer(page)}>
                            <SwapCallsTwoToneIcon/>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs>
                        <Tooltip title={'Delete page'}>
                          <IconButton color={'secondary'} onClick={() => setDeletePage(page)}>
                            <DeleteForeverTwoToneIcon/>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    <Button
                      fullWidth
                      variant={'outlined'}
                      color={'primary'}
                      onClick={() => savePage(page.id)}
                    >
                      Simpan
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            )
          })
        }
      </Grid>
      {
        !_.isEmpty(pageToTransfer) && (
          <Dialog fullScreen={isMobile} open={true} disableBackdropClick>
            <DialogTitle>Transfer a page</DialogTitle>
            <DialogContent className='dialog-content'>
              <TextField
                fullWidth
                label="FB ID"
                variant="outlined"
                value={fbIdTransfer}
                onChange={e => setFbIdTransfer(e.target.value)}/>
            </DialogContent>
            <DialogActions>
              <Button variant={'outlined'} fullWidth={isMobile} onClick={() => setPageToTransfer({})}>
                Cancel
              </Button>
              <Button variant={'outlined'} color={'primary'} fullWidth={isMobile} onClick={transferThePage}>
                Transfer
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      {
        !_.isEmpty(deletePage) && (
          <Dialog fullScreen={isMobile} open={true} disableBackdropClick>
            <DialogTitle>Confirm to delete page</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure to delete this page {deletePage.name}?
                This action cannot be undo
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant={'outlined'} fullWidth={isMobile} onClick={() => setDeletePage({})}>
                Cancel
              </Button>
              <Button variant={'outlined'} fullWidth={isMobile} color={'secondary'} onClick={confirmDeletePage}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )
      }

      <Snackbar
        open={!_.isEmpty(_.trim(snackbarMsg))}
        autoHideDuration={2000}
        onClose={() => setSnackbarMsg('')}
        message={snackbarMsg}
      />
    </StyledDiv>
  )
}
