import React, {useState} from 'react'
import styled from 'styled-components'

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined'

const StyledDiv = styled.div`
  ${({theme}) => `
  `}
`

// const debounceSearch = _.debounce((callback) => {
//   callback()
// }, 1000)


export default function Searchbox(props) {
  const {setSearchText} = props

  const [search, setSearch] = useState('')

  // useEffect(() => {
  //   debounceSearch(() => { setSearchText(search) })
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search])

  function submit(e) {
    e.preventDefault()
    e.stopPropagation()

    setSearchText(search)
  }

  return (
    <StyledDiv>
      <form onSubmit={submit}>
        <TextField
          value={search}
          fullWidth
          variant='outlined'
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setSearchText(search)}>
                  <FindInPageOutlinedIcon/>
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <button type={'submit'} value={'submit'} style={{display: 'none'}}></button>
      </form>
    </StyledDiv>
  )
}
