import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'

import 'react-datepicker/dist/react-datepicker.css'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import {impersonate, getAccount, updateAccount, deleteAccount, transferPage, updatePage, deletePage, updateInstagram, deleteInstagram} from './actions'
import Accountaccount from './account-account'
import Accountpages from './account-page'
import AccountInstagram from './account-instagram'

const StyledDiv = styled.div`
  ${({theme}) => `
    .alertBox {
      padding: ${theme.spacing(2)}px;
      border: 1px solid ${theme.palette.divider};
    }
  
    .alertBoxNoAccount {
      margin: ${theme.spacing(2)}px;
    }
    
    .alertBoxAccount {
      margin: ${theme.spacing(1)}px 0px ${theme.spacing(2)}px 0px;
    }
    
    .tab {
      position: fixed;
      top: 0;
      z-index: 99;
      width: 100vw;
      border-radius: 0px;
    }
  
    .container {
      padding: ${theme.spacing(2) + 48}px 0px ${theme.spacing(2)}px 0px;
      max-height: calc(100vh - 64px);
      
      @media (max-width: ${theme.breakpoints.values.md}px) {
        padding: ${theme.spacing(2) + 48}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px;
        max-height: unset;
      }
    }
  `}
`

const Account = (props) => {
  const {impersonate, account, setAccount, getAccount, match: {params}, setLoading, updateAccount, deleteAccount, transferPage, updatePage, deletePage, updateInstagram, deleteInstagram} = props

  const [tabIndex, setTabIndex] = useState(0)
  const [errMsg, setErrMsg] = useState('')
  const [fbPages, setFbPages] = useState([])
  const [instagramsInsta, setInstagramInsta] = useState([])
  const [originalFbPages, setOriginalFbPages] = useState([])
  const [originalInstagramInsta, setOriginalInstagramInsta] = useState([])

  useEffect(() => {
    if (_.isEmpty(account)) {
      getAccountManually()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!_.isEmpty(account)) {
      setFbPages(sortPage([...account.FacebookPages]))
      setOriginalFbPages([...account.FacebookPages])
      setInstagramInsta(sortPage([...account.InstagramInsta]))
      setOriginalInstagramInsta([...account.InstagramInsta])
    }
  }, [account])

  useEffect(() => {
    if (!_.isEmpty(_.trim(errMsg))) {
      setTimeout(() => setErrMsg(''), 4000)
    }

  }, [errMsg])

  function getAccountManually () {
    setLoading(true)
    getAccount(params.id)
      .then(response => {
        setAccount(response.data)
        setFbPages(sortPage([...response.data.FacebookPages]))
        setInstagramInsta(sortPage([...response.data.InstagramInsta]))
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            setErrMsg(err.response.data.msg)
          }
        } else {
          console.log(err)
        }
      })
      .then(() => setLoading(false))
  }

  function sortPage(pages) {
    return _.orderBy(pages, ['isConnected', 'isActive', 'isEnable', 'name'], ['desc', 'desc', 'desc', 'asc'])
  }

  return (
    <StyledDiv>
      {
        _.isEmpty(account) && !_.isEmpty(_.trim(errMsg)) && (
          <Box className={'alertBox alertBoxNoAccount'}><Typography color={'secondary'}>{errMsg}</Typography></Box>
        )
      }
      {
        !_.isEmpty(account) && (
          <>
            <Paper className={'tab'}>
              <Tabs
                value={tabIndex}
                onChange={(e, v) => setTabIndex(v)}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Maklumat"/>
                <Tab label="FB Pages"/>
                <Tab label="Instagram"/>
              </Tabs>
            </Paper>

            <Container className={'container'} fixed>
              {
                !_.isEmpty(_.trim(errMsg)) && (
                  <Box className={'alertBox alertBoxAccount'}><Typography color={'secondary'}>{errMsg}</Typography></Box>
                )
              }

              {
                tabIndex === 0 && (
                  <Accountaccount
                    account={account}
                    setLoading={setLoading}
                    setAccount={setAccount}
                    setErrMsg={setErrMsg}
                    updateAccount={updateAccount}
                    impersonate={impersonate}
                    deleteAccount={deleteAccount} />
                )
              }
              {
                tabIndex === 1 && (
                  <Accountpages
                    originalFbPages={originalFbPages}
                    fbPages={fbPages}
                    setLoading={setLoading}
                    setErrMsg={setErrMsg}
                    setFbPages={setFbPages}
                    getAccountManually={getAccountManually}
                    updatePage={updatePage}
                    transferPage={transferPage}
                    deletePage={deletePage}
                  />
                )
              }
              {
                tabIndex === 2 && (
                  <AccountInstagram
                    originalInstagramInsta={originalInstagramInsta}
                    instagramsInsta={instagramsInsta}
                    setLoading={setLoading}
                    setErrMsg={setErrMsg}
                    setInstagramInsta={setInstagramInsta}
                    getAccountManually={getAccountManually}
                    updateInstagram={updateInstagram}
                    deleteInstagram={deleteInstagram}
                  />
                )
              }
            </Container>
          </>
        )
      }
    </StyledDiv>
  )
}

export default connect((state) => ({...state.cnc}), (dispatch) => ({
  getAccount,
  impersonate,
  updateAccount,
  deleteAccount,
  transferPage,
  updatePage,
  deletePage,
  updateInstagram,
  deleteInstagram,
  setAccount: account => {
    dispatch({type: 'SET_ACCOUNT', data: account})
  },
  setLoading: loading => {
    dispatch({type: 'SET_LOADING', data: loading})
  }
}))(Account)