import React, {useRef, useEffect} from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import axios from 'axios'
import {withRouter} from 'react-router-dom'

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const StyledDiv = styled.div`
  ${({theme}) => `
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    
    > div {
      flex-grow: 1;
    }
  
  
    #login-container {
      position: relative;
      
      .overlay {
        position: absolute;
        width: 100%;
        height: 40px;
        z-index: 300;
        cursor: pointer;
      }
    }
  `}
`

const Login = (props) => {
  const {history, setToken} = props

  const timeout = useRef()

  useEffect(() => {
    let baseURL = 'https://api.bizsaya.com/'

    const {hostname, search, protocol, host} = window.location

    if (hostname === 'localhost') {
      baseURL = 'http://localhost:3000/'
    }

    if (!_.isEmpty(_.trim(search))) {
      const qs = {}
      search.replace(/^\?/, '').split('&').forEach(param => {
        const query = param.split('=')
        if (!_.isEmpty(query) && query.length === 2) {
          qs[query[0]] = query[1]
        }
      })

      if (!_.isEmpty(_.trim(qs.code))) {
        const redirect = `${protocol}//${host}/`

        axios.post(`${baseURL}auth`, {code: qs.code, url: redirect}, {params: {isAdmin: 'bizsayav4'}})
          .then(response => {
            history.push('/')
            setToken(response.data.token)
          })
          .catch(err => {
            showLoginBtn()
            if (err.response && err.response.status === 400) {
              alert(err.response.data.msg)
            } else {
              alert('Ralat pada sistem Bizsaya')
            }
          })
      } else {
        showLoginBtn()
      }
    } else {
      showLoginBtn()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function showLoginBtn () {
    timeout.current = setTimeout(() => {
      const element = document.getElementById('login-container')
      if (element) {
        FB.XFBML.parse(element)
      }
    }, 600)
  }

  function manualLogin () {
    const {protocol, host} = window.location
    const redirect = `${protocol}//${host}/`

    window.location.href = `https://www.facebook.com/v9.0/dialog/oauth?client_id=3046567165580925&redirect_uri=${redirect}&scope=public_profile,email`
  }

  return (
    <StyledDiv>
      <Grid container alignItems={'center'} justify={'center'}>
        <Grid item id={'login-container'}>
          <Box className={'overlay'} onClick={manualLogin}></Box>
          <div
            className="fb-login-button"
            data-size="large"
            data-button-type="login_with"
            data-layout="default"
            data-auto-logout-link="false"
            data-use-continue-as="true"
            data-scope="public_profile,email"
            data-onlogin="checkLoginState();"
          >
          </div>
        </Grid>
      </Grid>
    </StyledDiv>
  )
}

export default withRouter(Login)
