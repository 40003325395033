import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  ${({theme}) => `
    
  `}
`

export default function Instagram(props) {

  return (
    <StyledDiv>
            
    </StyledDiv>
  )
}
