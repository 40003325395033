import React from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const StyledDiv = styled.div`
  ${({theme}) => `
    .heading {
      padding: ${theme.spacing(1)}px;
    }
    
    a {
      text-decoration: none;
      color: ${theme.palette.primary.light};
    }
  `}
`

const Dashboard = (props) => {
  const {logs} = props

  return (
    <StyledDiv>
      <Typography className={'heading'} variant={'h5'}>
        Bizsaya CNC
      </Typography>
      <List dense>
        {
          logs.map((l, i) => {
            return (
              <ListItem key={i}>
                <ListItemText
                  primary={<span dangerouslySetInnerHTML={{ __html: l }} />}
                  primaryTypographyProps={{ variant: 'caption' }}
                />
              </ListItem>
            )
          })
        }
      </List>
    </StyledDiv>
  )
}

export default connect((state) => ({...state.cnc}), () => ({}))(Dashboard)
