import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import _ from 'lodash'

const initialState = {
  loading: false,
  logs: [],
  accounts: [],
  account: {},
  pages: [],
  page: {},
  ig: []
}

function cnc (state = initialState, action) {
  switch (action.type) {
    case 'SET_LOADING': {
      return {...state, loading: _.isBoolean(action.data)? action.data : false}
    }
    case 'SET_LOGS': {
      return {...state, logs: _.isArray(action.data)? [...action.data] : []}
    }
    case 'SET_ACCOUNTS': {
      return {...state, accounts: _.isArray(action.data)? [...action.data] : []}
    }
    case 'SET_ACCOUNT': {
      return {...state, account: _.isObject(action.data)? {...action.data} : {}}
    }
    case 'SET_PAGES': {
      return {...state, pages: _.isArray(action.data)? [...action.data] : []}
    }
    case 'SET_PAGE': {
      return {...state, page: _.isObject(action.data)? {...action.data} : {}}
    }
    case 'SET_IG': {
      return {...state, ig: _.isArray(action.data)? [...action.data] : []}
    }
    case 'ADD_LOGS': {
      const logsClone = [...state.logs]
      logsClone.unshift(action.data)

      if (logsClone.length > 499) {
        logsClone.pop()
      }

      return {...state, logs: logsClone}
    }
    case 'UPDATE_ACCOUNTS': {
      const accountsClone = [...state.accounts]
      const index = _.findIndex(accountsClone, {id: action.data.id})
      if (index === -1) {
        return {...state, accounts: accountsClone}
      }

      accountsClone[index] = action.data

      return {...state, accounts: accountsClone}
    }
    default: {
      return state
    }
  }
}

export default createStore(combineReducers({ cnc }), applyMiddleware(thunk))