export default `
.datepicker-container {
  min-height: 275px;
  
  &.pageversion {
    min-height: unset;
  }
  
  .react-datepicker__day, .react-datepicker__navigation {
    outline: none !important;
  }
  
  .react-datepicker__day--outside-month {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .react-datepicker__day--disabled {
    color: rgba(0,0,0,0.2);
  }
  
  .react-datepicker__day--disabled {
    background-color: transparent !important;
  }
  
  .react-datepicker__day--today {
    font-weight: normal;
  }
  
  .react-datepicker__input-container {
    input {
      width: 100%;
      border: 0px;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      cursor: pointer;
      outline: none;
    }
  }
}
`