import axios from 'axios'
import Cookie from 'js-cookie'

const { hostname } = window.location
let baseURL = 'http://localhost:3000'
if (hostname === 'cnc.bizsaya.com') {
  baseURL = 'https://api.bizsaya.com'
} else if (hostname === 'app2.bizsaya.com') {
  baseURL = 'https://api2.bizsaya.com'
}

const requester = axios.create({
  baseURL,
  timeout: 60000,
  transformResponse: [(data, response) => {
    if (response['content-type'].match(/application\/json/)) {
      if (data === 'null') {
        return  {}
      }

      const dataJson = JSON.parse(data)
      const errorMessages = [
        'Invalid authentication',
        'Token expired',
        'Token must be provided',
        'Token is invalid',
        'Error get user account detail'
      ]

      if (errorMessages.indexOf(dataJson.msg) !== -1) {
        Cookie.remove('ssid')
        window.location.reload(true)
      } else {
        return JSON.parse(data)
      }
    } else {
      return data
    }
  }]
})

export const setToken = token => {
  requester.defaults.headers.common.Authorization = token
}

export const getAccounts = (options = {}) => {
  return requester.get('/admin/accounts', options)
}

export const getAccount = (id, options = {}) => {
  return requester.get(`/admin/accounts/${id}`, options)
}

export const getFacebookPages = () => {
  return requester.get('/admin/fb-pages')
}

export const getInstagrams = () => {
  return requester.get('/admin/instagram')
}

export const impersonate = account => {
  return requester.get(`/admin/accounts/${account.id}/impersonate`)
}

export const updateAccount = account => {
  return requester.put(`/admin/accounts/${account.id}`, account)
}

export const deleteAccount = account => {
  return requester.delete(`/admin/accounts/${account.id}`, account)
}

export const transferPage = (page, fbId) => {
  return requester.post(`/admin/fb-pages/${page.id}`, {fbId})
}

export const updatePage = page => {
  return requester.put(`/admin/fb-pages/${page.id}`, page)
}

export const deletePage = page => {
  return requester.delete(`/admin/fb-pages/${page.id}`)
}

export const updateInstagram = instagram => {
  return requester.put(`/admin/instagram/${instagram.id}`, instagram)
}

export const deleteInstagram = instagram => {
  return requester.delete(`/admin/instagram/${instagram.id}`)
}