import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import _ from 'lodash'
import axios from 'axios'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import {impersonate, getAccounts} from './actions'
import Searchbox from './searchbox'

const CancelToken = axios.CancelToken
const axiosSource = CancelToken.source()

const StyledDiv = styled.div`
  ${({theme}) => `
    .container {
      padding: ${theme.spacing(2)}px;
    }
    
    .listing {
      margin-top: ${theme.spacing(3)}px;
      padding-bottom: ${theme.spacing(2)}px;
      
      .paper {
        padding: ${theme.spacing(1)}px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        button {
          margin-bottom: ${theme.spacing(1)}px;
          
          &:last-child {
            margin-bottom: 0px;
          }
        }
        
        &.paid {
          background-color: rgba(82, 238, 28, 0.4);
        }
        
        &.expiredpaid {
          background-color: rgba(254, 98, 98, 0.4);
        }
        
        &.trial {
          background-color: rgba(253, 253, 87, 0.4)
        }
      }
    }
  `}
`

const Accounts = (props) => {
  const {history, accounts, getAccounts, setAccounts, setAccount, setLoading} = props

  const [search, setSearch] = useState('')
  const [progressDone, setProgressDone] = useState(true)
  const [accountLists, setAccountList] = useState([])

  useEffect(() => {
    return () => {
      if (progressDone === false) {
        axiosSource.cancel('Cancel by user')
      }
      setAccounts([])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (_.isEmpty(accounts)) {
      setLoading(true)
      setProgressDone(false)
      getAccounts({cancelToken: axiosSource.token})
        .then(response => {
          response.data.map(acc => {
            acc.isExpired = dayjs(acc.expiredAt).startOf('date') < dayjs().startOf('date')
            return acc
          })

          setAccounts(response.data)
          setAccountList(_.orderBy(response.data, ['isExpired', 'isTrial', 'expiredAt'], ['asc', 'asc', 'asc']))
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.log('Call is cancelled')
          } else {
            alert('Error get all accounts')
          }
        })
        .then(() => {
          setProgressDone(true)
          setLoading(false)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts])

  useEffect(() => {
    if (_.isEmpty(_.trim(search))) {
      return setAccountList(_.orderBy(accounts, ['isExpired', 'isTrial', 'expiredAt'], ['asc', 'asc', 'asc']))
    }

    const newLists = []
    const searchRegex = new RegExp(search, 'i')

    accounts.forEach(acc => {
      if (acc.name.match(searchRegex)) {
        newLists.push(acc)
      } else {
        _.each(acc.FacebookPages, page => {
          if (page.name.match(searchRegex)) {
            newLists.push(acc)
            return false
          }
        })
        // _.each(acc.InstagramInsta, ig => {
        //   console.log(ig)
        // })
      }
    })

    setAccountList(newLists)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  function viewThis(account) {
    setAccount(account)
    history.push(`/accounts/${account.id}`)
  }

  function beginImpersonate(account) {
    impersonate(account)
      .then(response => {
        window.open(`https://app.bizsaya.com?token=${response.data.token}`, '_blank')
      })
      .catch(err => {
        alert(err.toString())
      })
  }

  return (
    <StyledDiv>
      <Box className='container'>
        <Searchbox setSearchText={setSearch} />

        <Box className='listing'>
          <Grid container spacing={2}>
            {
              accountLists.map(account => {
                let className = ''
                if (!account.isExpired && !account.isTrial) {
                  className = 'paid'
                } else if (account.isExpired && !account.isTrial) {
                  className = 'expiredpaid'
                } else if (!account.isExpired && account.isTrial) {
                  className = 'trial'
                }

                return (
                  <Grid key={account.id} item xs={12} md={3} lg={2}>
                    <Paper className={`paper ${className}`}>
                      <Avatar
                        src={`https://graph.facebook.com/v2.10/${account.fbId}/picture`}
                        alt='Gambar facebook'
                      />
                      <Typography>{account.name}</Typography>
                      <Typography>{dayjs(account.expiredAt).format('DD MMM YYYY')}</Typography>
                      <Typography variant={'caption'}>{account.fbId}</Typography>
                      <br/>
                      <Button
                        fullWidth
                        color='primary'
                        size='small'
                        variant='outlined'
                        onClick={() => viewThis(account)}
                      >
                        Kemaskini
                        [ {account.FacebookPages.filter(p => p.isActive).length}-{account.InstagramInsta.length} ]
                      </Button>
                      <Button
                        fullWidth
                        size='small'
                        variant='outlined'
                        onClick={() => beginImpersonate(account)}
                      >
                        Impersonate
                      </Button>
                    </Paper>
                  </Grid>
                )
              })
            }
          </Grid>
        </Box>
      </Box>
    </StyledDiv>
  )
}

export default connect((state) => ({...state.cnc}), (dispatch) => ({
  getAccounts,
  setAccounts: accounts => {
    dispatch({type: 'SET_ACCOUNTS', data: accounts})
  },
  setAccount: account => {
    dispatch({type: 'SET_ACCOUNT', data: account})
  },
  setLoading: loading => {
    dispatch({type: 'SET_LOADING', data: loading})
  }
}))(withRouter(Accounts))
